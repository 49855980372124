import { red, lime, teal, grey } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: lime[50],
            light: '#fff',
            dark: '#fff',
            contrastText: '#fff',
        },
        secondary: {
            main: teal[400],
            light: '#fff',
            dark: '#fff',
            contrastText: '#000',
        },
        error: {
            main: red.A100,
        },
        background: {
            default: '#fff',
        },
        breakpoints: {
            mobile: "only screen and (max-width: 50rem)",
            tablet: "only screen and (max-width: 65rem)",
        },
    },
});

export default theme;